<template>
	<div>
    <Loader v-if="!eventInfo || !eventInfo.id" />
    <div v-if="eventInfo.id">
      <form ref="form" @submit.prevent>

        <div class="dashboard__container--body">
          <div class="dashboard__container--body--col">
            <div class="whiteBack mb-5">
              <OrderNote :event="event" :me="userProfile" />
            </div>
            <div class="whiteBack mb-5">
              <NotesTable :notes="eventNotes" />
            </div>

            <div class="whiteBack mb-5">
              <h4>Notes From Client</h4>
              <!-- <label for="eventDescription">Description:</label> -->
              <vue-editor id="eventDescription" v-model="event.clientNotes"></vue-editor>
            </div>

          </div>
          <div class="dashboard__container--body--col">

            <div>
              <h4>Order Details</h4>
              <div class="mb-3">
                <label for="status">Order Status</label>
                <v-select
                  class="mt-2"
                  label="title" 
                  :options="statuses"
                  v-model="event.status"
                  :clearable="false"
                  @input="updateEventStatus"
                  >
                </v-select>
              </div>
              <div class="mb-3">
                <label for="eventName">Event/Job Title (optional):</label>
                <input type="text" v-model.trim="event.title" id="eventName" required />
              </div>
              <div class="mb-3">
                <label for="eventRef">Client PO/Reference Number:</label>
                <input type="text" v-model.trim="event.refNum" id="eventRef" />
              </div>
              <div class="mb-3">
                <span class="caption strong">Job Number: {{event.jobNum}}</span>
              </div>
              <div class="mb-2" v-if="venues && venues.length > 1">
                <label for="venue">Venue: (updates on change)</label>
                <v-select
                  class="mt-2"
                  label="title" 
                  :options="venues"
                  v-model="event.venue"
                  @input="updateVenue()"
                  >

                </v-select>
              </div>
              <div class="mb-3" v-if="event && event.venue && event.venue.address">
                <div class="caption"><span v-if="event.venue.address.street_number">{{event.venue.address.street_number}}</span><span v-if="event.venue.address.street"> {{event.venue.address.street}}</span><span v-if="event.venue.address.city">, {{event.venue.address.city}}</span><span v-if="event.venue.address.state"> {{event.venue.address.state}}</span><span v-if="event.venue.address.zip">, {{event.venue.address.zip}}</span></div>
                <div class="caption strong mt-1" v-if="event.venue.shortCode">CODE: {{event.venue.shortCode}}</div>
              </div>
             
              <!-- <div class="mb-3 pt-2">
                <label for="amazon">Amazon Location:</label>
                <input type="checkbox" v-model="event.amazon" id="amazon" class="ml-3" />
             </div>
              <div class="mb-3 pt-2">
                <label for="vax">Covid Vax Required:</label>
                <input type="checkbox" v-model="event.covidVax" id="vax" class="ml-3" />
             </div> -->
             <div class="mb-3" v-if="event">
                <label for="back">Background Check Requirements:</label>
                <v-select
                  class="mt-2"
                  label="title" 
                  :options="backgroundCheckTypes"
                  v-model="event.backgroundCheck"
                  >
                </v-select>
              </div>
              <div class="mb-3" v-if="event">
                <label for="drug">Drug Screening Requirements:</label>
                <v-select
                  class="mt-2"
                  label="title" 
                  :options="drugCheckTypes"
                  v-model="event.drugScreening"
                  >
                </v-select>
              </div>
              <div class="mb-3" v-if="event">
                <label for="glanceHours">At-A-Glance Hours:</label>
                <input type="text" v-model.trim="event.glanceHours" id="glanceHours" />
              </div>
              <div class="mb-3" v-if="event">
                <label for="glanceRate">At-A-Glance Pay Rate:</label>
                <input type="text" v-model.trim="event.glanceRate" id="glanceRate" />
              </div>

              <div class="mb-3" v-if="clients.length >= 1">
                <label for="client">Client:</label>
                <v-select
                  class="mt-2"
                  label="title" 
                  :options="clients"
                  multiple
                  v-model="event.client"
                  >
                </v-select>
              </div>

              <div class="mb-3" v-if="event.client && clientContacts.length >= 1">
                <label for="client">Client Contacts:</label>
                <v-select
                  class="mt-2"
                  label="name" 
                  :options="clientContacts"
                  multiple
                  v-model="event.clientContacts"
                  >
                </v-select>
              </div>
              <div class="mb-3" v-if="event.clientContacts && event.clientContacts.length >= 1">
                <div class="mb-3" v-for="(item, index) in event.clientContacts">
                  <h5>{{item.name}}</h5>
                  <div class="caption">{{item.title}}</div>
                  <div class="caption">{{item.phone}}</div>
                  <div class="caption">{{item.email}}</div>
                </div>
              </div>

              <div class="mb-3" v-if="subClients.length >= 1">
                <label for="subclient">Sub Client:</label>
                <v-select
                  class="mt-2"
                  label="title" 
                  :options="subClients"
                  multiple
                  v-model="event.subClient"
                  >
                </v-select>
              </div>

              


             
              <!-- <div class="mb-3">
                <label for="mgrs">Emails for Nightly Reports (ABM):</label>
                <input type="text" v-model="event.reportEmails" />
              </div> -->
            </div>

            <div class="whiteBack mt-5">
            
            <div class="mb-3" v-if="mgrs.length >= 1">
            <label for="pickDate">Staffing Managers:</label>
              <v-select
                class="mt-2"
                label="name" 
                :options="mgrs"
                multiple
                v-model="event.mgrs"
                >
              </v-select>
            </div>
            <div class="mb-3" v-if="(groups && groups.length >= 1)">
            <label for="groups">Associate with Groups:</label>
              <v-select
                class="mt-2"
                label="title" 
                :options="groups"
                v-model="event.groups"
                multiple
                >
              </v-select>
            </div>

            <div class="mb-3">
            <label for="types">Worker Type:</label>
              <v-select
                class="mt-2"
                label="title" 
                :options="types"
                v-model="event.workerType"
                :clearable="false"
                >
              </v-select>
            </div>
            <div class="mb-3">
              <label for="mgrs">Order Type:</label>
                <v-select
                  class="mt-2"
                  label="title" 
                  :options="typess"
                  v-model="event.shiftType"
                  :clearable="false"
                  >
                </v-select>
              </div>
              <div class="mb-3">
                <label for="date mb-2">Start/Order Date:</label>
                <!-- <label for="Date">Date:</label> -->
                <input type="date" v-model.trim="event.startDate"  id="Date"  />
              </div>


              <div class="mb-3">
                <label for="min">Minimum Hours to be billed and paid per shift (optional):</label>
                <input type="number" v-model.trim="event.minHours"  id="min" />
              </div>
        
            
            </div>


              
         
          </div>
        </div>
       
        <div class="dashboard__container--body">
          
    

          <div class="floating_buttons">
            <button class="btn btn__success btn__large" @click="updateEvent()" :disabled="event.status == 'cancelled'">
            Update Order
              <transition name="fade">
                <span class="ml-2" v-if="performingRequest">
                <i class="fa fa-spinner fa-spin"></i>
                </span>
              </transition>
            </button>
          </div>

          <div class="dashboard__container--body--col" style="background: transparent;">
            <div class="flex justify-space-between" style="background: transparent;">


              <button class="btn btn__danger btn__large" @click="showCancellation = true" v-if="!showCancellation && event.status != 'cancelled'">
                Cancel Order
                <transition name="fade">
                  <span class="ml-2" v-if="performingRequest4">
                  <i class="fa fa-spinner fa-spin"></i>
                  </span>
                </transition>
              </button>

              <button class="btn btn__primary btn__large" @click="showCancellation = false" v-if="showCancellation">
                No Don't Cancel
                <transition name="fade">
                  <span class="ml-2" v-if="performingRequest4">
                  <i class="fa fa-spinner fa-spin"></i>
                  </span>
                </transition>
              </button>


              <button class="btn btn__danger btn__large" @click="cancelEvent()" v-if="showCancellation">
                Yes, Cancel Order
                <transition name="fade">
                  <span class="ml-2" v-if="performingRequest4">
                  <i class="fa fa-spinner fa-spin"></i>
                  </span>
                </transition>
              </button>
              <button class="btn btn__success" @click="activateEvent()" v-if="event.status == 'cancelled'">
                 Activate Order
                <transition name="fade">
                  <span class="ml-2" v-if="performingRequest4">
                  <i class="fa fa-spinner fa-spin"></i>
                  </span>
                </transition>
              </button>
              <!-- <button class="btn btn__dark" @click="deleteEvent()">delete event</button> -->
            </div>
      		</div>
      	</div>
      </form>
    </div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import { VueEditor } from "vue2-editor";
import router from '@/router'
import NotesTable from '@/components/Orders/NotesTable.vue'
import OrderNote from '@/components/Orders/OrderNote.vue'
const fb = require('../../../firebaseConfig.js')
import * as moment from 'moment'

export default {
  props: ['eventInfo', 'eventShifts', 'eventShiftsDays'],
  name: 'orderDetails',
  data: () => ({
    croppa: {},
    day:'',
    types: ['Contractors', 'Employees'],
    drugs: ['None', '4-Panel', '6-Panel', '9-Panel'],
    backs: ['None', 'Basic', 'Platinum'],
    typess: ['Gig', 'Job'],
    statuses: ['Open', 'On-Hold', 'Closed'],
    showCancellation: false,
    job: '',
    multiDay: false,
    performingRequest: false,
    performingRequest2: false,
    performingRequest3: false,
    performingRequest4: false,
    imageData: null,
    fileTitle: '',
    fileDescription: '',
    uploadValue: 0,
    showBar:false,
    columns: [
      {
        label: 'Title',
        field: 'title',
      },
      {
        label: 'Description',
        field: 'description',
      },
      {
        label: 'Url',
        field: 'url',
        tdClass: 'text-right',
      },
      {
        label: '',
        field: 'extras',
        tdClass: 'text-right',
      },
    ]
  }),
  components: {
    VueEditor,
    Loader,
    NotesTable,
    OrderNote
  },
  created () {
    this.$store.dispatch("getBackgroundCheckTypes")
    this.$store.dispatch("getDrugCheckTypes")
  },
  mounted() {
    if (!this.venues || this.venues.length < 1) {
      this.$store.dispatch("getVenues")
    }
    if (!this.clients || this.clients.length < 1) {
        this.$store.dispatch("getClients")
    }
    if (!this.subClients || this.subClients.length < 1) {
        this.$store.dispatch("getSubClients")
    }
    // this.getClientContacts()
    // this.getTheClients()
    // if (this.eventInfo && this.eventInfo.id) {
    //     await this.$store.dispatch("getClientContacts", this.eventInfo)
    //   }
   
    if (!this.mgrs || this.mgrs.length < 1) {
      this.$store.dispatch("getMgrsState")
    }
    if (!this.groups || this.groups.length < 1) {
      this.$store.dispatch("getGroups")
    }
   
    
  },
  computed: {
    ...mapState(['clientContacts',  'userProfile', 'currentUser', 'venues', 'clients', , 'mgrs', 'groups', 'eventNotes', 'backgroundCheckTypes', 'drugCheckTypes', 'subClients']),
    event() {
      return this.eventInfo
    },
    // backgroundUrl() {
    //   return this.eventInfo.photoUrl || 'https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/stadium.png?alt=media&token=89f2362c-d1bc-4338-a837-fad1d664c51d'
    // },
  },
  methods: {
    getClientContacts() {
      if (this.eventInfo && this.eventInfo.id) {
        this.$store.dispatch("getClientContacts", this.eventInfo)
      }
    },
    getTheClients() {
      if (!this.clients || this.clients.length < 1) {
        this.$store.dispatch("getClients")
      }
    },
    // updateEventJobs2() {
    //   this.$store.dispatch('updateEventJobs2')
    // },
    addGroup (item) {
      console.log(item)
      let event = this.event
      this.$store.dispatch('updateEvent', event)
    },
    removeGroup (item) {
      console.log(item)
      let event = this.event
      this.$store.dispatch('updateEvent', event)
    },
    venueSync() {
      let venueId = this.eventInfo.venueId
      fb.venuesCollection.doc(venueId).get()
      .then(doc => {
        console.log(doc.data().job)
        this.$store.dispatch('updateEventJobs', {
          eventId: this.eventInfo.id,
          venue: doc.data()
        })
      })
      // let logFields = {
      //     staffMember: this.currentUser.email,
      //     export: 'Venue Sync(Events)',
      //     eventId: this.eventInfo.id
      // }
      // this.$store.dispatch('sendEventLog', logFields)
    },
    previewImage(event) {
      this.uploadValue=0;
      this.imageData=event.target.files[0]
    },
    onUploadFile() {
      this.showBar = true
      let event = this.eventInfo
      let fileTitle = this.fileTitle
      let fileDescription = this.fileDescription
      let rand = (Math.random().toString(36).substring(2, 16) + Math.random().toString(36).substring(2, 16)).toUpperCase()
      let storageRef = fb.storageRef.child('docs/' + rand).put(this.imageData);
      storageRef.on(`state_changed`, snapshot => {
        this.uploadValue=(snapshot.bytesTransferred/snapshot.totalBytes)*100;
      }, error => {console.log(error.message)},
      () => {this.uploadValue=100;
        storageRef.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log('File available at', downloadURL)
            var docRef = fb.eventsCollection.doc(event.id)
            docRef.update({
              files: fb.firestore.FieldValue.arrayUnion({
                title: fileTitle,
                description: fileDescription,
                url: downloadURL
              })
            });
            event.files.push({
              title: fileTitle,
              description: fileDescription,
              url: downloadURL
            })
        })
        this.showBar = false
      })
      this.imageData = null
      this.fileTitle = ''
      this.fileDescription = ''
      this.$refs.fileInputTip.value=null
    },
    onFileTypeMismatch(file) {
      alert('Invalid file type. Please choose a jpeg or png file.')
    },
    onFileSizeExceed(file) {
      alert('Please choose a file smaller than 2MB')
    },
    goBack() {
      router.go(-1)
    },
    // addInitialDay() {
    //   let event = this.event
    //   event.days.push(this.event.startDate)
    //   this.$store.dispatch('updateEvent', event)
    // },
    // addDay() {
    //   let event = this.event
    //   event.days.push(this.day)
    //   this.day = ''
    //   this.$store.dispatch('updateEvent', event)
    // },
    addJob() {
      let event = this.event
      event.jobs.push(this.job)
      this.job = ''
      this.$store.dispatch('updateEvent', event)
    },
    deleteDay(index) {
      let event = this.event
      event.days.splice(index, 1)
      this.$store.dispatch('updateEvent', event)
    },
    deleteJob(index) {
      let event = this.event
      event.jobs.splice(index, 1)
      this.$store.dispatch('updateEvent', event)
    },
    deleteUploadedFile(u, index) {
      console.log(u)
      let event = this.event
      event.files.splice(index, 1)
      this.$store.dispatch('updateEvent', event)
    },
    deleteEvent() {
      let event = this.eventInfo
      this.$store.dispatch('deleteEvent', event.id)
      let logFields = {
          staffMember: this.currentUser.email,
          export: 'Delete Event',
          eventId: this.event
      }
      this.$store.dispatch('sendEventLog', logFields)
      let url = `/orders`
      router.push(url)
    },
    async updateEventStatus() {
      await fb.eventsCollection.doc(this.event.id).update({
        status: this.event.status
      })
      this.$store.dispatch('updateEvent', event)
    },
    updateVenue() {
      let event = this.event
      event.venueId = this.event.venue.id
      event.venue_title = this.event.venue.title
      event.event_city = this.event.venue.address.city
      event.event_state = this.event.venue.address.state
      event.event_zip = this.event.venue.address.zip
      event.event_location = this.event.venue.center
      this.updateEvent()
      // this.$store.dispatch('updateEventVenue', event)
    },
    updateStaff() {
      let event = this.event
      this.$store.dispatch('updateEventStaff', event)
      let logFields = {
          staffMember: this.currentUser.email,
          export: 'Update Staff (Events)',
          eventId: this.event
      }
      this.$store.dispatch('sendEventLog', logFields)
    },
    cancelEvent() {
      this.performingRequest4 = true
      this.showCancellation = false
      this.event.status = 'cancelled'
      this.event.visible = false
      let event = this.event
      this.$store.dispatch('updateEvent', event)
      setTimeout(() => {
        this.performingRequest4 = false
      }, 500)
    },
    activateEvent() {
      this.performingRequest4 = true
      this.event.status = 'active'
      this.event.visible = true
      let event = this.event
      this.$store.dispatch('updateEvent', event)
      setTimeout(() => {
        this.performingRequest4 = false
      }, 500)
    },
    updateEvent() {
      this.performingRequest = true
    	let event = this.event
      let store = this.$store

      console.log(event)
      this.$store.dispatch('updateEvent', event)
      setTimeout(() => {
        this.performingRequest = false
      }, 2000)
    }
  },

  beforeDestroy () {
    this.$store.dispatch('clearGroups')
    this.$store.dispatch('clearClientsState')
    this.$store.dispatch('clearSubClients')
    this.$store.dispatch('clearVenuesState')
    this.$store.dispatch('clearClientContacts')
    this.$store.dispatch("clearMgrsState")
    this.croppa = null
    this.imageData = null
    this.showBar = null
    this.job = null
    this.multiDay = null
    this.fileTitle = null
    this.fileDescription = null
    this.performingRequest = null
    this.performingRequest2 = null
    this.performingRequest3 = null
    this.performingRequest4 = null
    this.day = null
    this.uploadValue = null
    this.columns = null
    delete this.imageData
    delete this.day
    delete this.job
    delete this.croppa
    delete this.multiDay
    delete this.fileTitle
    delete this.fileDescription
    delete this.performingRequest
    delete this.performingRequest2
    delete this.performingRequest3
    delete this.performingRequest4
    delete this.uploadValue
    delete this.showBar
    delete this.columns
    this.types = null
    delete this.types
    this.typess = null
    delete this.typess
    this.drugs = null
    delete this.drugs
    this.backs = null
    delete this.backs
    this.statuses = null
    delete this.statuses
    this.showCancellation = null
    delete this.showCancellation
    delete this.updateEvent
    delete this.activateEvent
    delete this.cancelEvent
    delete this.updateStaff
    delete this.updateVenue
    delete this.deleteEvent
    delete this.deleteUploadedFile
    delete this.deleteJob
    delete this.deleteDay
    delete this.addInitialDay
    delete this.addJob
    delete this.addDay
    delete this.onFileTypeMismatch
    delete this.goBack
    delete this.onFileSizeExceed
    delete this.onUploadFile
    delete this.previewImage
    delete this.venueSync
    delete this.removeGroup
    delete this.addGroup
    delete this.removeGroup
    this.$store.dispatch("clearBackgroundCheckTypes")
    this.$store.dispatch("clearDrugCheckTypes")
  },
  destroyed() {
    console.log(this)
  }
}
</script>